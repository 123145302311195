import { Button, Row } from "react-bootstrap";
import React from "react";
const AddButton: React.FC<{ func: any; label: string }> = ({ func, label }) => {
  return (
    <Row className="justify-content-center">
      <Button onClick={func}>{label}</Button>
    </Row>
  );
};
export default AddButton;
