import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { examPaperSchema } from "../ExamSchema";
import { Button, Col, Row } from "react-bootstrap";
import Divider from "../Atoms/Divider";
import BasicExamDetails from "./BasicExamDetails";
import SectionsWrapper from "./SectionsWrapper";
import usePersistedState from "../../utils/usePersistedState";
import connection from "../../utils/connection";
import Basic404 from "../Atoms/Basic404";
import { initialValues } from "../../utils/initials";
import { useHistory } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import FormikErrorFocus from "formik-error-focus";

interface AddExamProps {}

export const SetFieldValueContext = React.createContext(
  (undefined as unknown) as (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void
);

const AddExam: React.FC<AddExamProps> = (props) => {
  useBeforeunload(() => "You'll lose your data!");
  const history = useHistory();
  const [lastExamName, setLastExamName] = usePersistedState(
    "lastExamName",
    (null as unknown) as string
  );

  const [examNames, setExamNames] = usePersistedState(
    "examNames",
    ("" as unknown) as Array<string>
  );
  const [error, setError] = useState("loading");
  useEffect(() => {
    console.log("start");
    if (lastExamName === null) {
      (async () => {
        try {
          const resp = await connection.get("/exams/");
          const data = resp.data;
          if (data.length) {
            const examNames = data
              ?.map(({ examName }: { examName: string }) => examName)
              .sort();
            const lastName = examNames[examNames.length - 1];
            setLastExamName(lastName);
            setExamNames(examNames);
          }
          setError("done");
        } catch (error) {
          console.error(error);
          setError("error");
        }
      })();
    } else {
      setError("done");
    }
    return () => {
      sessionStorage.removeItem("lastExamName");
      sessionStorage.removeItem("examNames");
      localStorage.removeItem("exam-form");
    };
  }, []);
  if (error !== "done") {
    return <Basic404 errorType={error}></Basic404>;
  }
  return (
    <div className="wrapper__section">
      <h2>Basic Exam Details</h2>
      <br></br>
      <Formik
        initialValues={initialValues}
        validationSchema={examPaperSchema}
        onSubmit={(values, actions) => {
          (async () => {
            connection
              .post("/exams", values)
              .then((data) => {
                history.push("/add-done");
                actions.setSubmitting(false);
              })
              .catch((error) => {
                console.error(error);
                actions.setSubmitting(false);
              });
          })();
        }}>
        {({ values, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <SetFieldValueContext.Provider value={setFieldValue}>
                <BasicExamDetails
                  examNames={examNames}
                  lastExamName={lastExamName}
                  startPageValue={values.startPage}></BasicExamDetails>
                <Divider></Divider>
                <SectionsWrapper sections={values.sections}></SectionsWrapper>
                <Divider></Divider>
                <Row>
                  <Col>
                    <Button type="submit" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </SetFieldValueContext.Provider>
              {/* <Persist name="exam-form" /> */}
              <FormikErrorFocus
                // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
                offset={0}
                align={"top"}
                focusDelay={200}
                duration={300}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddExam;
