import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import connection from "../../utils/connection";
import { useLocation } from "react-router-dom";
import Basic404 from "../Atoms/Basic404";
import { Form, Formik } from "formik";
import { examPaperUpdateSchema, examPaperUpdateType } from "../ExamSchema";
import BasicExamDetails from "./BasicExamDetails";
import SectionsWrapper from "./SectionsWrapper";
import Divider from "../Atoms/Divider";
import { Button, Col, Row } from "react-bootstrap";
// import { Persist } from "formik-persist";
import { useBeforeunload } from "react-beforeunload";
import FormikErrorFocus from "formik-error-focus";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const SetFieldValueContext = React.createContext(
  (undefined as unknown) as (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void
);
interface ParamTypes {
  examName: string;
}
interface EditExamProps extends RouteComponentProps {}

const EditExam: React.FC<EditExamProps> = (props) => {
  useBeforeunload(() => "You'll lose your data!");

  const queryParams = useQuery();
  const history = useHistory();
  const { examName } = useParams<ParamTypes>();
  const [error, setError] = useState("loading");
  const [values, setValues] = useState(
    (undefined as unknown) as examPaperUpdateType
  );
  useEffect(() => {
    (async () => {
      try {
        console.log(examName);
        const resp = await connection.get(
          `exams/get?examName=${examName}&userType=admin`
        );
        const values = { ...resp.data, examName: examName };

        console.log(values);
        setValues(values);
        setError("done");
      } catch (err) {
        console.error(err);
        setError("error");
      }
    })();

    return () => {
      localStorage.removeItem("exam-edit-form");
    };
  }, []);
  if (error !== "done") {
    return <Basic404 errorType={error}></Basic404>;
  }
  return (
    <div className="wrapper__section">
      <h2>Basic Exam Details</h2>
      <br></br>
      <Formik
        initialValues={values}
        validationSchema={examPaperUpdateSchema}
        onSubmit={(values, actions) => {
          let data = values as any;
          data.examName = examName;
          connection
            .put("/exams", data)
            .then((data) => {
              history.push("/edit-done");
            })
            .catch((error) => {
              console.error(error);
              actions.setSubmitting(false);
            });
        }}>
        {({ values, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <SetFieldValueContext.Provider value={setFieldValue}>
                <BasicExamDetails
                  examName={examName as string}
                  startPageValue={values.startPage}></BasicExamDetails>
                <Divider></Divider>
                <SectionsWrapper sections={values.sections}></SectionsWrapper>
                <Divider></Divider>
                <Row>
                  <Col>
                    <Button type="submit" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </SetFieldValueContext.Provider>
              <FormikErrorFocus
                // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
                offset={0}
                align={"top"}
                focusDelay={200}
                duration={300}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditExam;
