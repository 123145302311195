import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import connection from "../../utils/connection";
import Basic404 from "../Atoms/Basic404";

interface HomeProps {
  setLoggedIn: any;
}
const TableRender: React.FC<{
  examName: string;
  editPassword: any;
  editContent: any;
}> = ({ examName, editPassword, editContent }) => (
  <tr>
    <td>{examName}</td>
    <td>
      {
        <Button size="sm" onClick={editPassword(examName)}>
          Edit Password
        </Button>
      }
    </td>
    <td>
      {
        <Button size="sm" onClick={editContent(examName)}>
          Edit Content
        </Button>
      }
    </td>
  </tr>
);
const Home: React.FC<HomeProps> = (props) => {
  const [error, setError] = useState("loading");
  const [examNames, setExamNames] = useState(([] as unknown) as any);
  const [password, setPassword] = useState("");
  const [examName, setExamName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalError, setModalError] = useState(false);
  const history = useHistory();
  useEffect(() => {
    (async () => {
      try {
        const resp = await connection.get(`/exams`);
        const data = resp.data;
        if (data.length) {
          setExamNames(
            data.map(({ examName }: { examName: string }) => examName)?.sort()
          );
        }
        setError("done");
      } catch (err) {
        console.error(err);
        setError("error");
      }
    })();
  }, []);
  if (error !== "done") {
    return <Basic404 errorType={error}></Basic404>;
  }
  const editPassword = (examName: string) => () => {
    setShowPassword(true);
    setExamName(examName);
  };
  const editContent = (examName: string) => () => {
    history.push(`/edit/${examName}`);
  };
  const handleClose = () => setShowPassword(false);
  const handlePasswordEdit = () => {
    setIsSubmitting(true);
    (async () => {
      try {
        await connection.put("/exams/password", {
          examName: examName,
          password: password,
        });
        setIsSubmitting(false);
        setShowPassword(false);
        setExamName("");
        setPassword("");
      } catch (err) {
        setModalError(true);
        setIsSubmitting(false);
        console.error(err);
      }
    })();
  };
  return (
    <>
      <div className="wrapper__section">
        <h3> Welcome admin!</h3>
        <br></br>
        <Row>
          <Col>
            <Button onClick={() => history.push("add-exam")}> Add Exam</Button>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button onClick={() => props.setLoggedIn(false)}> Log Out</Button>
          </Col>
        </Row>
        <br></br>
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Exam Name</th>
                <th>Edit Password</th>
                <th>Edit Content</th>
              </tr>
            </thead>
            <tbody>
              {examNames?.length > 0 &&
                examNames.map((examNameItem: string) => (
                  <TableRender
                    key={`${examNameItem}-key`}
                    editPassword={editPassword}
                    examName={examNameItem}
                    editContent={editContent}></TableRender>
                ))}
            </tbody>
          </Table>
        </div>
        <Modal show={showPassword} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup controlId="formBasicPassword">
              <FormLabel>You are editing password for {examName}</FormLabel>
              <FormControl
                placeholder="Enter password"
                value={password}
                onChange={(event) => setPassword(event.currentTarget.value)}
              />
            </FormGroup>
            {modalError ? (
              <div className="my-1 text-danger">Error Occured! try again.</div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={isSubmitting}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handlePasswordEdit}
              disabled={isSubmitting}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Home;
