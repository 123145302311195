import React from "react";
import { Button, Row } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router-dom";

interface DoneProps extends RouteComponentProps {}

const Done: React.FC<DoneProps> = (props) => {
  const history = useHistory();
  return (
    <>
      <div className="wrapper__section">
        <h3>Exam Added</h3>
        <br></br>
        <Row className="justify-content-center">
          <Button onClick={() => history.push("/")}>Go to Home</Button>
        </Row>
      </div>
    </>
  );
};

export default Done;
