import React, { useState } from "react";
import { Button } from "react-bootstrap";

const DeleteButton: React.FC<{
  func: () => void;
  sectionIndex?: number;
  label: string;
  variant?: string;
}> = ({ func, label, sectionIndex, variant = "danger" }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <div>
      <br></br>
      <Button
        variant={variant}
        onClick={() => setShowConfirm(true)}
        disabled={sectionIndex === 0 ? true : false}>
        {label}
      </Button>
      {showConfirm ? (
        <Button className="ml-2" variant="outline-danger" onClick={func}>
          Confirm
        </Button>
      ) : null}
      <hr></hr>
    </div>
  );
};
export default DeleteButton;
