import { ErrorMessage, Field } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";

interface TextInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  formText?: {
    type: "warning" | "danger" | "info";
    text: string;
  };
  validate?: (value: string) => void;
  as?: "textarea";
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  placeholder,
  type,
  formText,
  as,
  validate,
}) => {
  return (
    <>
      <Row className="mb-3">
        <Col>
          {label ? (
            <label htmlFor={name} className="form-label">
              {label}
            </label>
          ) : null}
          <Field
            validate={validate}
            as={as}
            type={type}
            className="form-control"
            id={name}
            name={name}
            placeholder={placeholder}
          />
          {formText ? (
            <div
              id={`${name}Help`}
              className={`form-text ${
                formText.type ? `text-${formText.type}` : ""
              }`}>
              <i
                className={`bi align-text-bottom ${
                  formText.type === "warning" || "info"
                    ? "bi-exclamation-circle"
                    : ""
                }`}></i>
              {"  "}
              {formText.text}
            </div>
          ) : null}
          <ErrorMessage name={name} className="text-danger" component="div" />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(TextInput);
