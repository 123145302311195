import { FieldArray } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FormCheck } from "react-bootstrap";
import TextEditor from "../Atoms/TextEditor";
import { sectionType } from "../ExamSchema";
import DeleteButton from "../Atoms/DeleteButton";
import InnerSection from "./InnerSectionWrapper";
import AddButton from "../Atoms/AddButton";
import { SetFieldValueContext } from "./EditExam";
import { initialInnerSections } from "../../utils/initials";
interface SectionProps {
  sectionIndex: number;
  section: sectionType;
  removeFunction: () => void;
}

const Section: React.FC<SectionProps> = ({
  sectionIndex,
  section,
  removeFunction,
}) => {
  const [sixAnswers, setSixAnswers] = useState(
    section["innerSections"][0]?.questionsList[0]?.answersList.length > 4
      ? true
      : false
  );

  const setFieldValue = useContext(SetFieldValueContext);
  const [isCaseStudy, setIsCaseStudy] = useState(
    section["innerSections"][0]?.description?.title ? true : false
  );
  useEffect(() => {
    if (!isCaseStudy) {
      setFieldValue(
        `sections[${sectionIndex}]innerSections`,
        section[`innerSections`].slice(0, 1)
      );
    }
  }, [isCaseStudy]);
  return (
    <div>
      <h4>Section {sectionIndex + 1}</h4>

      <br></br>
      <h5>Section Settings</h5>
      <FormCheck
        id={`${sectionIndex}-caseStudySwitch`}
        type="switch"
        label="Case Study Section"
        checked={isCaseStudy}
        // defaultChecked={isCaseStudy}
        onChange={(event) => {
          setIsCaseStudy(event.target.checked);
        }}></FormCheck>
      <FormCheck
        id={`${sectionIndex}-sixAnswersSwitch`}
        type="switch"
        label="Enable Six Answers"
        checked={sixAnswers}
        // defaultChecked={isCaseStudy}
        onChange={(event) => {
          setSixAnswers(event.target.checked);
        }}></FormCheck>
      <br></br>
      <TextEditor
        type="edit"
        value={section["sectionDetails"]}
        label="Section Details"
        fieldName={`sections.${sectionIndex}.sectionDetails`}></TextEditor>
      <br></br>

      <FieldArray name={`sections[${sectionIndex}]innerSections`}>
        {({
          name: innerSectionName,
          remove: innerSectionRemove,
          push: innerSectionPush,
        }) => {
          return (
            <>
              {section["innerSections"]?.length > 0 &&
                section["innerSections"].map(
                  (innerSection, innerSectionIndex) => {
                    return (
                      <InnerSection
                        sixAnswers={sixAnswers}
                        innerSectionRemove={innerSectionRemove}
                        innerSectionPush={innerSectionPush}
                        innerSection={innerSection}
                        innerSectionName={innerSectionName}
                        isCaseStudy={isCaseStudy}
                        key={`section-${sectionIndex}-innerSection-${innerSectionIndex}`}
                        innerSectionIndex={innerSectionIndex}></InnerSection>
                    );
                  }
                )}
              {isCaseStudy ? (
                <AddButton
                  func={() => innerSectionPush(initialInnerSections)}
                  label="Add Case Study"></AddButton>
              ) : null}
              <br></br>
              <hr></hr>
              <DeleteButton
                label="Delete Section"
                key={`${sectionIndex}-delete-btn`}
                sectionIndex={sectionIndex}
                func={removeFunction}></DeleteButton>
            </>
            // return {
          );
        }}
      </FieldArray>
    </div>
  );
};

export default React.memo(Section);
