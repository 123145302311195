import React from "react";
import { Spinner } from "react-bootstrap";

interface Basic404Props {
  errorType: string;
}

const Basic404: React.FC<Basic404Props> = (props) => {
  console.log(props.errorType);
  if (props.errorType === "loading") {
    return (
      <div
        style={{ height: "80vh" }}
        className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  if (props.errorType === "404") {
    return (
      <>
        <div className="page-wrap d-flex flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <span className="display-1 d-block">404</span>
                <div className="mb-4 lead">
                  The page you are looking for was not found.
                </div>
                {/* <a
                  href="https://www.totoprayogo.com/#"
                  className="btn btn-link">
                  Back to Home
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.errorType === "error") {
    return (
      <>
        <div className="page-wrap d-flex flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <span className="display-1 d-block">Error</span>
                <div className="mb-4 lead">Error Occured! Try again.</div>
                {/* <a
                  href="https://www.totoprayogo.com/#"
                  className="btn btn-link">
                  Back to Home
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return null;
};

export default Basic404;
