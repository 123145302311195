import React, { ReactElement } from "react";
import TextEditor from "../Atoms/TextEditor";
import TextInput from "../Atoms/TextInput";

interface Props {
  startPageValue: string;
  examName: string;
}

export default React.memo(function BasicExamDetails({
  startPageValue,
  examName,
}: Props): ReactElement {
  return (
    <>
      <h3>{examName}</h3>
      <TextEditor
        type="edit"
        value={startPageValue}
        fieldName="startPage"
        label="Exam Details (Start Page)"></TextEditor>
    </>
  );
});
