import { FieldArray } from "formik";
import React from "react";
import { sectionType } from "../ExamSchema";
import { initialSections } from "../../utils/initials";
import Section from "./Section";
import AddButton from "../Atoms/AddButton";
interface SectionsWrapperProps {
  sections: Array<sectionType>;
}

const SectionsWrapper: React.FC<SectionsWrapperProps> = ({ sections }) => {
  return (
    <>
      <h2>Section Details</h2>
      <br></br>
      {/* <SectionCounter
    label="Section Count"
    sectionCount={sectionCount}
    setSectionCount={setSectionCount}></SectionCounter> */}
      <FieldArray name="sections">
        {({ remove: sectionRemove, push: sectionPush }) => (
          <div>
            {sections.length > 0 &&
              sections.map((section, sectionIndex) => (
                <Section
                  removeFunction={() => sectionRemove(sectionIndex)}
                  section={section}
                  sectionIndex={sectionIndex}
                  key={`section-${sectionIndex}`}></Section>
              ))}
            <AddButton
              label="Add Section"
              func={() => sectionPush({ ...initialSections })}></AddButton>
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default SectionsWrapper;
