import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

interface LoginProps {
  loggedIn: boolean;
  setLoggedIn: any;
}

const Login: React.FC<LoginProps> = ({ loggedIn, setLoggedIn }) => {
  const [falseCred, setFalseCred] = useState(false);
  const [inputUserName, setInputUserName] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const userName = "admin";
  const password = "ccbmexams";
  return (
    <div className="wrapper__section">
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          if (userName === inputUserName && password === inputPassword) {
            setLoggedIn(true);
          } else {
            setFalseCred(true);
          }
        }}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>User name</Form.Label>
          <Form.Control
            placeholder="Enter username"
            value={inputUserName}
            onChange={(event) => setInputUserName(event.currentTarget.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={inputPassword}
            onChange={(event) => setInputPassword(event.currentTarget.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
        {falseCred ? (
          <div className="my-2 text-danger">
            Credentials are wrong! Please recheck.
          </div>
        ) : null}
      </Form>
    </div>
  );
};

export default Login;
