import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import AddButton from "../Atoms/AddButton";
import CheckInput from "../Atoms/CheckInput";
import DeleteButton from "../Atoms/DeleteButton";
import TextInput from "../Atoms/TextInput";
import AnswerWrapper from "./AnswerWrapper";
import { questionType } from "../ExamSchema";
import { answerType } from "../ExamSchema";
import Answer from "./Answer";

interface QuestionProps {
  question: questionType;
  questionIndex: number;
  questionName: string;
  questionRemove: (obj: any) => void;
  sixAnswers: boolean;
}

const Question: React.FC<QuestionProps> = ({
  question,
  questionIndex,
  questionName,
  questionRemove,
  sixAnswers,
}) => {
  const validateCheck = (value: Array<string>) => {
    if (!value?.length) {
      return "Please enter the correct answers!";
    }
  };
  // console.log(question);
  return (
    <>
      <Row>
        <Col>
          <TextInput
            as="textarea"
            label={`Question ${questionIndex + 1}`}
            placeholder="Enter the question here"
            name={`${questionName}[${questionIndex}.question]`}></TextInput>
        </Col>
        <Col
          xs={2}
          sm={1}
          className="d-flex align-items-center mb-3"
          style={{ marginTop: 32 }}>
          <Button
            disabled={questionIndex === 0 ? true : false}
            variant="outline-danger"
            size="sm"
            onClick={() => questionRemove(questionIndex)}>
            X
          </Button>
        </Col>
      </Row>
      <FieldArray name={`${questionName}[${questionIndex}]answersList`}>
        {({
          name: answerName,
          remove: answerRemove,
          push: answerPush,
          pop,
        }) => (
          <AnswerWrapper
            answerName={answerName}
            answerPush={answerPush}
            pop={pop}
            question={question}
            questionName={questionName}
            sixAnswers={sixAnswers}></AnswerWrapper>
        )}
      </FieldArray>
      <CheckInput
        sixAnswers={sixAnswers}
        values={question["correctAnswers"]}
        validate={validateCheck}
        name={`${questionName}[${questionIndex}]correctAnswers`}></CheckInput>
    </>
  );
};

export default Question;
