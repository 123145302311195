import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Basic404 from "./features/Atoms/Basic404";
import AddExam from "./features/Exam/AddExam";
import Done from "./features/Exam/Done";
import EditExam from "./features/EditExam/EditExam";
import Home from "./features/Home/Home";
import EditDone from "./features/EditExam/EditDone";
import usePersistedState from "./utils/usePersistedState";
import Login from "./features/Login/Login";

function App() {
  const [loggedIn, setLoggedIn] = usePersistedState("loggedIn", false);
  if (!loggedIn) {
    return (
      <div className="app">
        <div className="wrapper__main">
          <Switch>
            <Route
              render={(props) => (
                <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
              )}></Route>
          </Switch>
        </div>
      </div>
    );
  }
  return (
    <div className="app">
      <div className="wrapper__main">
        <Switch>
          <Route exact path="/add-exam" component={AddExam}></Route>
          <Route exact path="/add-done" component={Done}></Route>
          <Route exact path="/edit-done" component={EditDone}></Route>
          <Route exact path="/edit/:examName" component={EditExam}></Route>
          <Route
            exact
            path="/"
            render={(props) => (
              <Home {...props} setLoggedIn={setLoggedIn} />
            )}></Route>
          <Route
            render={(props) => {
              return <Basic404 errorType={"404"}></Basic404>;
            }}></Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
