import { FieldArray } from "formik";
import React, { useContext, useEffect } from "react";
import AddButton from "../Atoms/AddButton";
import DeleteButton from "../Atoms/DeleteButton";
import TextInput from "../Atoms/TextInput";
import { innerSectionType, questionType } from "../ExamSchema";
import { SetFieldValueContext } from "./EditExam";
import { initialQuestionsList } from "../../utils/initials";
import Question from "./Question";
interface innerSectionProps {
  innerSectionName: string;
  innerSectionIndex: number;
  isCaseStudy: boolean;
  innerSection: innerSectionType;
  innerSectionPush: (obj: any) => void;
  innerSectionRemove: (obj: any) => void;
  sixAnswers: boolean;
}

const InnerSectionWrapper: React.FC<innerSectionProps> = ({
  innerSectionIndex,
  innerSectionName,
  isCaseStudy,
  innerSection,
  innerSectionPush,
  innerSectionRemove,
  sixAnswers,
}) => {
  const setFieldValue = useContext(SetFieldValueContext);
  useEffect(() => {
    if (isCaseStudy && !innerSection?.description?.title) {
      setFieldValue(
        `${innerSectionName}[${innerSectionIndex}].description.title`,
        `Case Study ${innerSectionIndex + 1}`,
        true
      );
    }
  }, [isCaseStudy]);
  return (
    <>
      <div>
        {isCaseStudy ? (
          <>
            <h5> {innerSection?.description?.title}</h5>

            <TextInput
              label="Enter Case Study Paragraph"
              name={`${innerSectionName}[${innerSectionIndex}].description.paragraph`}
              type="text"
              as="textarea"></TextInput>
          </>
        ) : null}
        <FieldArray
          name={`${innerSectionName}[${innerSectionIndex}]questionsList`}>
          {({
            name: questionName,
            remove: questionRemove,
            push: questionPush,
          }) => {
            return (
              <>
                {innerSection["questionsList"]?.length > 0 &&
                  innerSection[
                    "questionsList"
                  ].map((question: questionType, questionIndex: number) => (
                    <Question
                      sixAnswers={sixAnswers}
                      questionRemove={questionRemove}
                      key={`${innerSectionName}-question-${questionIndex}`}
                      question={question}
                      questionIndex={questionIndex}
                      questionName={questionName}></Question>
                  ))}
                <AddButton
                  label="Add Question"
                  func={() => questionPush(initialQuestionsList)}></AddButton>
              </>
            );
          }}
        </FieldArray>
        {isCaseStudy ? (
          <DeleteButton
            label="Delete Case Study"
            func={() => innerSectionRemove(innerSectionIndex)}
            sectionIndex={innerSectionIndex}></DeleteButton>
        ) : null}
      </div>
    </>
  );
};

export default InnerSectionWrapper;
