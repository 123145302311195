import JoditEditor from "jodit-react";
import React, { useContext } from "react";
import { ErrorMessage } from "formik";
import { SetFieldValueContext } from "../Exam/AddExam";
import { SetFieldValueContext as EditFieldValueContext } from "../EditExam/EditExam";
interface TextEditorProps {
  value: string;
  label: string;
  fieldName: string;
  type?: "edit";
  // onChange: (value: any) => void;
}

const TextEditor: React.FC<TextEditorProps> = (props) => {
  const setFieldValue = useContext(SetFieldValueContext);
  const editFieldValue = useContext(EditFieldValueContext);
  return (
    <div className="mb-3">
      <label className="form-label">{props.label}</label>
      <JoditEditor
        value={props.value}
        //@ts-ignore
        config={{ readonly: false, toolbarSticky: false }}
        tabIndex={1} // tabIndex of textarea
        onBlur={(value: any) => {
          if (props.type === "edit") {
            editFieldValue(props.fieldName, value.target.innerHTML, true);
          } else {
            setFieldValue(props.fieldName, value.target.innerHTML, true);
          }
        }}
      />
      <ErrorMessage
        name={props.fieldName}
        className="text-danger"
        component="div"
      />
    </div>
  );
};

export default React.memo(TextEditor);
