import React, { ReactElement } from "react";
import TextEditor from "../Atoms/TextEditor";
import TextInput from "../Atoms/TextInput";

interface Props {
  startPageValue: string;
  lastExamName: string;
  examNames: Array<string>;
}

export default React.memo(function BasicExamDetails({
  startPageValue,
  lastExamName,
  examNames,
}: Props): ReactElement {
  function validateExamName(value: string) {
    let error;

    if (examNames?.length && examNames.indexOf(value) !== -1) {
      error = "Exam name exists! Please choose a different name.";
    }

    return error;
  }
  return (
    <>
      <TextInput
        validate={validateExamName}
        label={`Exam name (Last exam name: ${lastExamName})`}
        name="examName"
        formText={{
          type: "info",
          text:
            "Warning! Exam name cannot be changed later! Please be sure that it is correct.",
        }}
        placeholder="Enter exam name"></TextInput>

      <TextInput
        label="Exam password"
        name="password"
        placeholder="Enter exam password"></TextInput>
      <TextEditor
        value={startPageValue}
        fieldName="startPage"
        label="Exam Details (Start Page)"></TextEditor>
    </>
  );
});
