import React, { useEffect } from "react";
import usePrevious from "../../utils/usePrevious";
import { answerType } from "../ExamSchema";
import Answer from "./Answer";

interface AnswerWrapperProps {
  sixAnswers: boolean;
  answerPush: any;
  pop: any;
  question: any;
  answerName: any;
  questionName: any;
}

const AnswerWrapper: React.FC<AnswerWrapperProps> = ({
  sixAnswers,
  answerPush,
  pop,
  question,
  answerName,
  questionName,
}) => {
  const prevSixAnswers = usePrevious(sixAnswers);
  useEffect(() => {
    if (sixAnswers) {
      ["e", "f"].map((value: string) =>
        answerPush({ label: "", value: value })
      );
    } else if (prevSixAnswers === true && sixAnswers === false) {
      console.log("test");
      pop();
      pop();
    }
  }, [sixAnswers]);
  return (
    <>
      {question["answersList"]?.length > 0 &&
        question[
          "answersList"
        ].map((answer: answerType, answerIndex: number) => (
          <Answer
            answer={answer}
            answerIndex={answerIndex}
            answerName={answerName}
            key={`${questionName}-answer-${answerIndex}`}></Answer>
        ))}
    </>
  );
};

export default AnswerWrapper;
