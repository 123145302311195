import React from "react";
import { Col, Row } from "react-bootstrap";
import TextInput from "../Atoms/TextInput";
import { answerType } from "../ExamSchema";

interface AnswerProps {
  answer: answerType;
  answerIndex: number;
  answerName: string;
}

const Answer: React.FC<AnswerProps> = ({ answer, answerName, answerIndex }) => {
  return (
    <Row className="ml-1">
      <Col className=" px-2 d-flex align-items-center" style={{ flexGrow: 0 }}>
        <p>{`${answer.value})`}</p>
      </Col>
      <Col>
        <TextInput name={`${answerName}[${answerIndex}].label`}></TextInput>
      </Col>
    </Row>
  );
};

export default Answer;
