import { array, InferType, object, string } from "yup";

export const descriptionSchema = object({
  paragraph: string(),
  title: string(),
})
  .noUnknown()
  .notRequired();
export const answersListSchema = object({
  value: string().required(),
  label: string().required("Please enter the answer!"),
});

export const questionSchema = object({
  question: string().required("Please enter the question!"),
  correctAnswers: array()
    .of(string().required("Please select the correct answers!"))
    .ensure()
    .required("Please enter the correct answers!"),
  answersList: array()
    .of(answersListSchema.noUnknown().required())
    .ensure()
    .required(),
})
  .noUnknown()
  .required();

export const innerSectionSchema = object({
  description: descriptionSchema,
  questionsList: array().of(questionSchema).ensure().required(),
})
  .noUnknown()
  .required();
const sectionSchema = object({
  sectionDetails: string().required("Please enter section details"),
  innerSections: array().of(innerSectionSchema).ensure().required(),
}).required();
export const examPaperSchema = object({
  examName: string().required("Please enter the exam name"),
  password: string().required("Please enter the exam password"),
  startPage: string().required("Please enter the start page details"),
  sections: array(sectionSchema).ensure().required(),
}).required();
export type answerType = InferType<typeof answersListSchema>;
export type descriptionType = InferType<typeof descriptionSchema>;
export type questionType = InferType<typeof questionSchema>;
export type answersListType = questionType["answersList"];
export type correctAnswersType = questionType["correctAnswers"];
export type innerSectionType = InferType<typeof innerSectionSchema>;
export type questionsListType = innerSectionType["questionsList"];
export type sectionType = InferType<typeof sectionSchema>;
export type examPaperType = InferType<typeof examPaperSchema>;

export type examPaperDetails = {
  examName: examPaperType["examName"];
  password: examPaperType["password"];
  startPage: examPaperType["startPage"];
};
// export interface examPaperType extends Asserts<typeof examPaperSchema> {}

export const examPasswordSchema = object({
  examName: string().required(),
  password: string().required(),
});
export type examPasswordType = InferType<typeof examPasswordSchema>;
// export interface examPasswordType extends Asserts<typeof examPasswordSchema> {}
export const examPaperUpdateSchema = object({
  startPage: string().required("Please enter the start page details"),
  sections: array(sectionSchema).ensure().required(),
}).required();

export type examPaperUpdateType = InferType<typeof examPaperUpdateSchema>;
// export interface examPaperUpdateType
//   extends Asserts<typeof examPaperUpdateSchema> {}
