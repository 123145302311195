import { ReactElement } from "react";

export default function Divider(): ReactElement {
  return (
    <>
      <br></br>
      <hr></hr>
      <br></br>
    </>
  );
}
