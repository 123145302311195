import {
  answersListType,
  examPaperType,
  innerSectionType,
  questionType,
  sectionType,
} from "../features/ExamSchema";

export const initialAnswerList: answersListType = [
  {
    label: "",
    value: "a",
  },
  {
    label: "",
    value: "b",
  },
  {
    label: "",
    value: "c",
  },
  {
    label: "",
    value: "d",
  },
];
export const initialQuestionsList: questionType = {
  answersList: initialAnswerList,
  correctAnswers: [],
  question: "",
};
export const initialInnerSections: innerSectionType = {
  description: {
    paragraph: undefined,
    title: undefined,
  },
  questionsList: [initialQuestionsList],
};
export const initialSections: sectionType = {
  sectionDetails: "",
  innerSections: [initialInnerSections],
};
export const initialValues: examPaperType = {
  examName: "",
  password: "",
  startPage: "",
  sections: [initialSections],
};
