import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BooleanSchema } from "yup";
import { correctAnswersType } from "../ExamSchema";

interface CheckInputProps {
  values?: correctAnswersType;
  name: string;
  validate: (value: Array<string>) => string | undefined;
  sixAnswers: boolean;
}

const CheckInput: React.FC<CheckInputProps> = ({
  values,
  name,
  validate,
  sixAnswers,
}) => {
  //   console.log(values);
  const [defaultValue, setDefaultValue] = useState(
    (undefined as unknown) as {
      a: boolean;
      b: boolean;
      c: boolean;
      d: boolean;
      e?: boolean;
      f?: boolean;
    }
  );
  useEffect(() => {
    if (values) {
      const data = {
        a: values?.indexOf("a") === -1 ? false : true,
        b: values?.indexOf("b") === -1 ? false : true,
        c: values?.indexOf("c") === -1 ? false : true,
        d: values?.indexOf("d") === -1 ? false : true,
        e: values?.indexOf("e") === -1 ? false : true,
        f: values?.indexOf("f") === -1 ? false : true,
      };
      setDefaultValue(data);
    }
  }, [values]);
  return (
    <>
      <Row>
        <Col className="d-flex justify-content-end">
          <label className="form-label">Select Correct Answers :</label>
        </Col>
        <Col>
          <Row>
            <div className="form-check">
              <Field
                checked={defaultValue?.a}
                validate={validate}
                type="checkbox"
                name={name}
                value="a"
                className="form-check-input"
                id={`${name}-a`}
              />
              <label htmlFor={`${name}-a`} className="form-check-label">
                a
              </label>
            </div>
            <span className="text-black-50 mx-1">{"|"}</span>
            <div className="form-check">
              <Field
                checked={defaultValue?.b}
                id={`${name}-b`}
                type="checkbox"
                name={name}
                value="b"
                className="form-check-input"
              />
              <label htmlFor={`${name}-b`} className="form-check-label">
                b
              </label>
            </div>
            <span className="text-black-50 mx-1">{"|"}</span>
            <div className="form-check">
              <Field
                checked={defaultValue?.c}
                id={`${name}-c`}
                type="checkbox"
                name={name}
                value="c"
                className="form-check-input"
              />
              <label htmlFor={`${name}-c`} className="form-check-label">
                c
              </label>
            </div>
            <span className="text-black-50 mx-1">{"|"}</span>
            <div className="form-check">
              <Field
                checked={defaultValue?.d}
                id={`${name}-d`}
                type="checkbox"
                name={name}
                value="d"
                className="form-check-input"
              />
              <label htmlFor={`${name}-d`} className="form-check-label">
                d
              </label>
            </div>
            {sixAnswers ? (
              <>
                <span className="text-black-50 mx-1">{"|"}</span>
                <div className="form-check">
                  <Field
                    checked={defaultValue?.e}
                    id={`${name}-e`}
                    type="checkbox"
                    name={name}
                    value="e"
                    className="form-check-input"
                  />
                  <label htmlFor={`${name}-e`} className="form-check-label">
                    e
                  </label>
                </div>
                <span className="text-black-50 mx-1">{"|"}</span>
                <div className="form-check">
                  <Field
                    checked={defaultValue?.f}
                    id={`${name}-f`}
                    type="checkbox"
                    name={name}
                    value="f"
                    className="form-check-input"
                  />
                  <label htmlFor={`${name}-f`} className="form-check-label">
                    f
                  </label>
                </div>
              </>
            ) : null}
          </Row>
        </Col>
      </Row>
      <ErrorMessage
        name={name}
        className="text-danger text-center"
        component="div"
      />

      <br></br>
    </>
  );
};

export default CheckInput;
