import { useState, useEffect } from "react";

export default function usePersistedState(key: string, defaultValue: any) {
    const [state, setState] = useState(
        () => sessionStorage.hasOwnProperty(key) ? JSON.parse(sessionStorage.getItem(key) as string) : defaultValue
    );
    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}